<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('systemTransactions.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/user-transaction'"
        :reloadData="reloadData"
        :createStatus="false"
        :excelFields="excelFields"
        excelName="systemTransactions"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import SystemTransactionFilter from "../models/SystemTransactionFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new SystemTransactionFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        {
          key: "transaction_value",
          label: this.$t("systemTransactions.transactionValue"),
          sortable: true,
        },
        {
          key: "old_credit",
          label: this.$t("systemTransactions.oldCredit"),
          sortable: true,
        },
        {
          key: "transaction_type",
          label: this.$t("systemTransactions.transactionType"),
          sortable: true,
        },
        {
          key: "source_type",
          label: this.$t("systemTransactions.sourceType"),
          sortable: true,
        },
        {
          key: "source_id",
          label: this.$t("systemTransactions.sourceId"),
          sortable: true,
        },
        {
          key: "direction",
          label: this.$t("systemTransactions.direction"),
          sortable: true,
        },
        {
          key: "systemWallet.name",
          label: this.$t("systemWallets.item"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "transactions.systemTransactionView",
              routeParams: ["id"],
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("systemTransactions.transactionValue")]: "transaction_value",
        [this.$t("systemTransactions.oldCredit")]: "old_credit",
        [this.$t("systemTransactions.transactionType")]: "transaction_type",
        [this.$t("systemTransactions.sourceType")]: "source_type",
        [this.$t("systemTransactions.sourceId")]: "source_id",
        [this.$t("systemTransactions.direction")]: "direction",
        [this.$t("systemWallets.item")]: "systemWallet.name",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  mounted() {
    core.index();
  },
};
</script>
